import request from "@utils/request";
// 获取会员信息
export function findMemberList (data) {
  return request({
    url: "user/findMemberList",
    method: "post",
    data,
  });
}
// 商品列表
export function serverMallList (data) {
  return request({
    url: "mall/productInit",
    method: "post",
    data,
  });
}

//商品购买列表
export function findMallVoByIds (data) {
  return request({
    url: "mall/findMallVoByIds",
    method: "post",
    data,
  });
}

// 商品收藏
export function commodityCollection (data) {
  return request({
    url: "mall/collectInsert",
    method: "post",
    data,
  });
}

// 取消收藏
export function cancelCollection (data) {
  return request({
    url: "mall/deleteCollect",
    method: "post",
    data,
  });
}

//商品详情
export function detailsList (id) {
  return request({
    url: `mall/findMallVoById/${id}`,
    method: "post",
  });
}
//商品作者
export function getauthor (data) {
  return request({
    url: "mall/findAuthorById",
    method: "post",
    data
  });
}
//用户评价
export function getcomment (data) {
  return request({
    url: "mall/findAllEvaluate",
    method: "post",
    data
  });
}

//热门
export function recommendProduct (data) {
  return request({
    url: "mall/recommendProduct",
    method: "get",
    data,
  });
}

//收藏
export function getCollection (data) {
  return request({
    url: "mall/findCollectList",
    method: "post",
    data,
  });
}

//查看更多
export function getMoreList (data) {
  return request({
    url: "mall/findMoreProduct",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//加入购物车
export function shoppingcat (data) {
  return request({
    url: "mall/saveToShopCart",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//搜索
export function getSearch (data) {
  return request({
    url: "mall/searchCommodity",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// 查看购物车
export function findShopCart (data) {
  return request({
    url: "mall/findShopCart",
    method: "post",
    data,
  });
}

//商城-购物车商品数量增减
export function shoppingcatNum (data) {
  return request({
    url: "mall/updateProductNum",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//商城-批量移出购物车
export function delshopingcat (data) {
  return request({
    url: "mall/batchDelFromShopCart",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//支付-下单
export function payment (data) {
  return request({
    url: "pay/placeOrder",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//添加评论
export function addcomment (data) {
  return request({
    url: "mall/saveEvaluate",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//点赞
export function pariseadmin (data) {
  return request({
    url: "bbs/endorse",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// 关注博主
export function focusUser(data){
  return request({
    url: 'user/focusUser',
    method: 'post',
    data,
  })
}